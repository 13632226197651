import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import * as Icon from 'react-feather';
import { Link } from "gatsby"; 




const BlogDetails = () => (
    <Layout>
        <SEO title="Conditions de service" /> 

        <Navbar />

        <PageBanner pageTitle="Conditions de service" /> 

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> Dernière mise à jour le 20 février 2023
                                        </li>
                                    </ul>
                                </div>

                                <h2>Conditions de service et d'utilisation</h2> 

                                <p>L'utilisateur s'engage à utiliser les services de manière saine et réfléchie. Toute activité douteuse ou frauduleuse des services fera l'objet d'une suspension immédiate.</p>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </Layout>
)

export default BlogDetails;